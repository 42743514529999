import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DevicesService } from '../../services/devices.service';
import { UsersService } from '../../services/users.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { MessagesService } from '../../services/messages.service';

import * as moment from 'moment';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
	selector: 'advanced',
	templateUrl: 'advanced.component.html',
	styleUrls: ['./advanced.component.scss']
})

export class AdvancedComponent implements OnInit, OnDestroy {
	@ViewChild('upgrade', {static: true}) public upgradeModal: ModalDirective;
	@ViewChild('addProModal', {static: true}) addProModal;
	@ViewChild('removeProModal', {static: true}) removeProModal;
	@ViewChild('setVelModal', {static: true}) setVelModal;

	readonly maxFailedRequests = 10;
	private failedRequests     = 0;

	public loggedUser: any;
	public disableBtn: boolean = false;
	public blockButtons: boolean = false;
	public devices: any;
	public professionals: any[];
	public devProfessionals: any[] = [];
	public selectedProfessional: number;
	public selectedDevice: any;
	public assignedProfessionals: number[] = [];
	public totalDevices: number;
	public limit: number = 10;
	public totalPages: number;
	public page: number = 1;
	public order: any = {
		field: 'vars/updated',
		direction: 'DESC',
	};
	public aliasDir: string = null;
	public fechaDir: string = null;
	public conditions: any = {
		parameters: {
			search: ''
		},
		advanced: 1
	};
	public _searchText: string = '';
	public userSearch = 1;
	public workMode: number;
	public defaultModes = {
		eco: null,
		turbo: null
	}
	public settingVel = false;
	public settingDefaults = false;
	public blockVelBtns = false;
	private _searchTimeOut: any = null;
	private _interval: any;

	get searchText(): string {
		return this._searchText;
	}

	set searchText(value: string) {
		if (this._searchTimeOut) {
			clearTimeout(this._searchTimeOut);
		}

		value = value.trim();

		this._searchTimeOut = setTimeout(() => {
			if (this._interval) {
				clearTimeout(this._interval);
			}

			if (value && value !== '') {
				this._searchText = value;
				this.conditions = {
					parameters: {
						search: this._searchText
					},
					advanced: 1
				};
			} else {
				this.conditions = {
					parameters: {
						search: ''
					}
				};
			}

			this.userSearch++;
			this.loadItems();
		}, 500);
	}

	set eco(value: number) {
		if (value <= this.turbo) {
			this.defaultModes.eco = this.turbo + 1;
		} else {
			this.defaultModes.eco = value;
		}
	}

	get eco() {
		return this.defaultModes.eco ? this.defaultModes.eco : 60;
	}

	set turbo(value: number) {
		if (value >= this.eco) {
			this.defaultModes.turbo = this.eco - 1;
		} else {
			this.defaultModes.turbo = value;
		}
	}

	get turbo() {
		return this.defaultModes.turbo ? this.defaultModes.turbo : 4;
	}

	constructor(
		private _devicesSvc: DevicesService,
		private _usersSvc: UsersService,
		private _router: Router,
		private _translate: TranslateService,
		private _authSvc: AuthService,
		private _messagesSvc: MessagesService,
		private _notificationService: NotificationsService) {
		this.loggedUser = this._authSvc.user;
	}

	ngOnInit() {
		if (this._interval) {
			clearTimeout(this._interval);
		} else {
			this.loadItems();
		}
	}

	ngOnDestroy() {
		clearTimeout(this._interval);
		this._interval = null;
	}

	public loadItems(page: number = 1) {
		if (this._interval) {
			clearTimeout(this._interval);
		}

		this.page = page;
		this.disableBtn = true;

		this._devicesSvc.find(this.limit, page, this.conditions, this.order, true, this.userSearch).then((result: any) => {
			this.failedRequests = 0;
			if (this.userSearch === parseInt(result.userSearch, 10)) {
				this.devices = result.items;
				this.totalDevices = result.total;
				this.totalPages = Math.ceil(this.totalDevices / this.limit);
				console.log(this.devices);
				this.disableBtn = false;

				if (this._router.url === '/advanced') {
					this._interval = setTimeout(() => {
						this.loadItems(this.page);
					}, 10000);
				}

				this.userSearch++;
			}
		}).catch((err) => {
			console.log(err);
			this.disableBtn = false;

			if (this._router.url === '/advanced') {
				this.failedRequests++;
				if(this.failedRequests < this.maxFailedRequests){
					this._interval = setTimeout(() => {
						this.loadItems(this.page);
					}, 10000);
				}
			}
		});
	}

	public addProfessional(index: number) {
		if (this._interval) {
			clearTimeout(this._interval);
		}

		this.assignedProfessionals = [];
		this.selectedProfessional = 0;
		this.professionals = null;
		this.selectedDevice = this.devices[index];
		this.addProModal.show();

		for (let professional of this.devices[index].professionals) {
			this.assignedProfessionals.push(parseInt(professional.id, 10));
		}

		this._usersSvc.getProfessionals(this.assignedProfessionals).then((professionals: any) => {
			this.professionals = professionals;
		}).catch((error: any) => {
			let message:string = "";
			message = this._translate.instant('PROFESIONALES_ERR')
			this._notificationService.html(message, NotificationType.Error);
			console.log(error.message);
			this.addProModal.hide();
		});
	}

	public associateProfessional() {
		this.blockButtons = true;

		this._usersSvc.addProfessional(this.selectedProfessional, this.selectedDevice.id).then((result: any) => {
			let message = {
				id: null,
				id_emisor: this.loggedUser.id_user,
				id_receptor: this.selectedProfessional,
				asunto: this._translate.instant('OK_ASIGNAR_DISPOSITIVO'),
				mensaje: `${this._translate.instant('MENSAJE_ASIGNACION_1')} <strong>${this.selectedDevice.alias}</strong> - ${this.selectedDevice.id_loc} (${this.selectedDevice.id_pais}). ${this._translate.instant('MENSAJE_ASIGNACION_2')} ${this.selectedDevice.user.nombre} ${this.selectedDevice.user.apellidos} ${this._translate.instant('MENSAJE_ASIGNACION_3')} <a href='mailto:${this.selectedDevice.user.usr}'>${this.selectedDevice.user.usr}</a> ${this._translate.instant('MENSAJE_ASIGNACION_4')}`,
				fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
				borrado_emisor: 0,
				borrado_receptor: 0,
				leido: 0
			};

			message.mensaje = message.mensaje.replace(/[&]/g, '%26');

			// tslint:disable-next-line:no-shadowed-variable
			this._messagesSvc.add(message).then((result: any) => {
				console.log(result.message);
				this.loadItems(this.page);
				this.blockButtons = false;
				this.addProModal.hide();
			}).catch((error: any) => {
				console.log(error.message);
				this.addProModal.hide();
			});
		});
	}

	public delProfessional(index: number) {
		if (this._interval) {
			clearTimeout(this._interval);
		}

		this.devProfessionals = this.devices[index].professionals;
		this.selectedProfessional = null;
		this.selectedDevice = this.devices[index];
		this.removeProModal.show();
	}

	public desassociatePro() {
		this.blockButtons = true;

		this._usersSvc.delProfessional(this.selectedProfessional, this.selectedDevice.id).then((result: any) => {
			console.log(result.message);
			this.blockButtons = false;
			this.loadItems(this.page);
			this.removeProModal.hide();
		}).catch((error: any) => {
			const { error: { message } } = error;
			this._notificationService.error('', this._translate.instant(message));
			this.blockButtons = false;
			this.removeProModal.hide();
		});
	}

	public changeOrder(order: any) {
		if (this._interval) {
			clearTimeout(this._interval);
		}

		this.page = 1;
		this.order.field = order.column;
		this.order.direction = order.direction;
		this.loadItems();
	}

	public setVel() {
		let mode = this.workMode.toString();

		this.settingVel = true;

		for (let i = mode.length; i < 3; i++) {
			mode = '0' + mode;
		}

		this._devicesSvc.setVel(this.selectedDevice.id, mode).then((result: any) => {
			this.settingVel = false;
			this.loadItems();
			console.log(result.message);
		}).catch((error) => {
			this.settingVel = false;
			this.workMode = +this.selectedDevice.vars.ep;
			console.log(error.message);
		});
	}

	public changeModeMenu(device: any) {
		// tslint:disable-next-line:forin
		this.workMode = (device.vars.ep && device.vars.ep !== '') ? +device.vars.ep : 0;
		this.defaultModes.eco = (parseInt(device.default_eco, 10) !== NaN) ? +device.default_eco : 60;
		this.defaultModes.turbo = (parseInt(device.default_turbo, 10) !== NaN) ? +device.default_turbo : 4;
		this.selectedDevice = device;
		this.setVelModal.show();
	}

	public setDefaultMode(mode: string) {
		let selEco = +this.selectedDevice.default_eco;
		let selTurbo = +this.selectedDevice.default_turbo;

		this.blockVelBtns = true;

		this._devicesSvc.changeMode(this.selectedDevice.id, mode, 0).then((result: any) => {
			this.workMode = (mode === 'fast') ? selTurbo : selEco;
			this.loadItems();
			this.blockVelBtns = false;
			console.log(result.message);
		}).catch((error: any) => {
			this.blockVelBtns = false;
			console.log(error.message);
		});
	}

	public changeAutoupdate(device, newValue) {
		device.autoupdate = Boolean(newValue);
		this._devicesSvc.changeAutoupdate(device.id, newValue);
	}

	public setDefaultVels() {
		let data = {
			eco: this.defaultModes.eco.toString(),
			turbo: this.defaultModes.turbo.toString()
		};

		this.settingDefaults = true;

		for (let i = data.eco.length; i < 3; i++) {
			data.eco = '0' + data.eco;
		}

		for (let i = data.turbo.length; i < 3; i++) {
			data.turbo = '0' + data.turbo;
		}

		this._devicesSvc.setDefaultVels(this.selectedDevice.id, data).then((result: any) => {
			this.settingDefaults = false;
			this.loadItems();
			console.log(result.message);
		}).catch((error) => {
			this.settingDefaults = false;
			console.log(error.message);
		});
	}
}
