export class AppConfig {
  // DEV
  public static HOME_API: string = 'https://api.poolstation.net'

  // PROD
  //public static HOME_API: string = 'https://api.poolstation.net';

  // URL NUEVOS FIRMWARES
  public static FIRM_URL: string = "idegis.net";

  public static CAPTCHA_KEY = {
    "localhost:4200": "6LfCkwUgAAAAAPQ7v8sq_vRH9428zKlkaJFCQc1n",
    "test.poolstation.net": "6LfCkwUgAAAAAPQ7v8sq_vRH9428zKlkaJFCQc1n",
    "poolstation.net": "6LcJkqIgAAAAANCD1r2ABbfsms-1K7ENFBYxmRe9",
    "idegis.net": "6LcJkqIgAAAAANCD1r2ABbfsms-1K7ENFBYxmRe9",
    "test.idegis.net": "6LdGB6wUAAAAAJzrMBMbHnOoVivFEXMzVH4aa7CS",
    "pre.poolstation.net": "6LcwnaYlAAAAAJD5jviWeFXw_kGnvuMgff56kb13",
    "pre.idegis.net": "6LcwnaYlAAAAAJD5jviWeFXw_kGnvuMgff56kb13"
  };
}
