<header ps-header>
	<h2 class="col-md-6 col-lg-8 col-xs-10">{{ docs[doc] | translate }}</h2>
	<div class="col-md-5 col-lg-3 col-xs-12 text-right pull-right">
		<select [(ngModel)]="doc" class="form-control hidden-xs" style="margin: 6px 0;">
			<option *ngFor="let option of docs | keys" [value]='option.key'>{{ option.value | translate }}</option>
		</select>
	</div>
</header>
<main class="container-fluid">
	<select [(ngModel)]="doc" class="form-control visible-xs" style="margin: 6px 0;">
		<option *ngFor="let option of docs | keys" [value]='option.key'>{{ option.value | translate }}</option>
	</select>
	<div [ngSwitch]="doc">
		<div *ngSwitchCase="'twilight'">
			<div class="col-sm-3 hidden-xs">
				<div position-fixed>
					<h4>{{ 'TM_TXT2' | translate }}</h4>
					<ul>
						<li><a pageScroll href="#selecction-mod1">{{ 'TM_TXT3' | translate }}</a></li>
						<li><a pageScroll href="#selecction-mod2">{{ 'TM_TXT4' | translate }}</a></li>
						<li><a pageScroll href="#selecction-mod3">{{ 'TM_TXT5' | translate }}</a></li>
						<li><a pageScroll href="#selecction-mod4">{{ 'TM_TXT6' | translate }}</a></li>
						<li><a pageScroll href="#selecction-mod5">{{ 'TM_TXT7' | translate }}</a></li>
						<li><a pageScroll href="#selecction-mod6">{{ 'TM_TXT8' | translate }}</a></li>
					</ul>
					<h4>{{ 'TM_TXT9' | translate }}</h4>
					<ul>
						<li><a pageScroll href="#selecction-mod-offset">{{ 'TM_TXT10' | translate }}</a></li>
					</ul>
				</div>
			</div>

			<div class="col-sm-9">
				<h3>{{ 'TM_TXT11'| translate }}</h3>
				<p>{{ 'TM_TXT12'| translate }}</p>
				<p>{{ 'TM_TXT13'| translate }}</p>
				<p>{{ 'TM_TXT14'| translate }}</p>
				<p class="text-center">
					<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/paso1.png'" />
        		</p>
					<p>{{ 'TM_TXT15'| translate }}</p>
					<p class="text-center">
						<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/paso2.png'" />
        		</p>
						<p>{{ 'TM_TXT16'| translate }}</p>
						<p class="text-center">
							<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/paso3.png'" />
        		</p>
							<h3>{{ 'TM_TXT17'| translate }}</h3>
							<h4 id="selecction-mod1"><strong>{{ 'TM_TXT18' | translate }}</strong></h4>
							<p>{{ 'TM_TXT19'| translate }}</p>
							<p class="text-center">
								<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/Esquema-1-crepuscular-bis.png'"/>
        		</p>
								<h4 id="selecction-mod2"><strong>{{ 'TM_TXT20' | translate }}</strong></h4>
								<p>{{ 'TM_TXT21'| translate }}</p>
								<p class="text-center">
									<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/Esquema-2-crepuscular-bis.png'"/>
        		</p>
									<h4 id="selecction-mod3"><strong>{{ 'TM_TXT22' | translate }}</strong></h4>
									<p>{{ 'TM_TXT23' | translate }}</p>
									<p class="text-center">
										<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/Esquema-3-crepuscular-bis.png'"/>
				</p>
										<h4 id="selecction-mod4"><strong>{{ 'TM_TXT24' | translate }}</strong></h4>
										<p>{{ 'TM_TXT25' | translate }}</p>
										<p class="text-center">
											<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/Esquema-4-crepuscular-bis.png'"/>
        		</p>

											<h4 id="selecction-mod5"><strong>{{ 'TM_TXT26' | translate }}</strong></h4>
											<p>{{ 'TM_TXT27' | translate }}</p>
											<p class="text-center">
												<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/Esquema-5-crepuscular-bis.png'"/>
        		</p>
												<h4 id="selecction-mod6"><strong>{{ 'TM_TXT28' | translate }}</strong></h4>
												<p>{{ 'TM_TXT29' | translate }}</p>
												<p class="text-center">
													<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/Esquema-6-crepuscular-bis.png'"/>
        		</p>
													<h3>{{ 'TM_TXT30' | translate }}</h3>
													<p>{{ 'TM_TXT31' | translate }}</p>
													<h4 id="selecction-mod-offset"><strong>{{ 'TM_TXT32' | translate }}</strong></h4>
													<p class="text-center">
														<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/offset1.png'"/>
        		</p>
														<p>{{ 'TM_TXT33' | translate }}</p>
														<p class="text-center">
															<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/offset2.png'"/>
        		</p>
															<p>{{ 'TM_TXT34' | translate }}</p>
															<p class="text-center">
																<img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/Captura.png'"/>
        		</p>
																<p>{{ 'TM_TXT35' | translate }}</p>
																<div *ngIf="lang==='es'">
																	<h3>{{ 'TM_TXT36' | translate }}</h3>
																	<p>{{ 'TM_TXT37' | translate }}</p>
																</div>
																<p><img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/icono-i.png'"/>{{ 'TM_TXT38' | translate }}</p><br />
																<p><img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/icono-grafica.png'"/>{{ 'TM_TXT39' | translate }}</p><br />
																<p><img class="img-responsive" [src]="'../../assets/images/dibujos_crepusculares/' + lang + '/icono-mapa.png'"/>{{ 'TM_TXT40' | translate }}</p>
			</div>
		</div>

		<div *ngSwitchCase="'cookies'" class="main tab-wrapper">
			<div class="container cookiesContainer">
				<h3>{{ 'QUE_ES_COOKIE' | translate }}</h3>
				<p [innerHTML]="'COOKIES_TEXTO_1' | translate"></p>
        <h3>{{ 'COOKIES_NECESARIAS' | translate }}</h3>
        <p [innerHTML]="'COOKIES_TEXTO_2' | translate"></p>
        <div [innerHTML]="'COOKIES_TABLA_1' | translate"></div>
        <h3>{{ 'MAS_INFO_COOKIES' | translate }}</h3>
        <p [innerHTML]="'COOKIES_TEXTO_3' | translate"></p>
			</div>
		</div>

		<div *ngSwitchCase="'privacy'" class="main tab-wrapper">
			<div class="container">
				<h3>{{ 'PRIVACY_HEADER' | translate }}</h3>
				<p [innerHTML]="'INTRO_TEXTO' | translate"></p>
				<h3>{{ 'COMPROMISE_HEADER' | translate }}</h3>
				<div [innerHTML]="'COMPROMISO_TEXTO' | translate" class="dots"></div>
				<div [innerHTML]="'COMPROMISO_TEXTO_2' | translate" class="dotsABC"></div>
				<h3>{{ '3_COMUNICACIONES' | translate }}</h3>
				<div [innerHTML]="'COMUNICACIONES_TEXTO' | translate"></div>
				<h3>{{ '4_DECISIONES' | translate }}</h3>
				<div [innerHTML]="'DECISIONES_TEXTO' | translate"></div>
				<h3>{{ '5_DURACION' | translate }}</h3>
				<div [innerHTML]="'DURACION_TEXTO' | translate"></div>
				<h3>{{ '6_DERECHOS' | translate }}</h3>
				<div [innerHTML]="'DERECHOS_TEXTO' | translate"></div>
				<h3>{{ '7_CONFIDENCIALIDAD' | translate }}</h3>
				<div [innerHTML]="'CONFIDENCIALIDAD_TEXTO' | translate"></div>
				<h3>{{ '8_REDES' | translate }}</h3>
				<div [innerHTML]="'REDES_TEXTO' | translate"></div>
			</div>
		</div>

		<div *ngSwitchCase="'inputs'">
			<div class="col-sm-3 hidden-xs">
				<div position-fixed>
					<h4>{{ 'IM_TXT3' | translate }}</h4>
					<ul>
						<li><a pageScroll href="#interlocking">{{ 'IM_TXT4' | translate }}</a></li>
						<li><a pageScroll href="#howConnect">{{ 'IM_TXT5' | translate }}</a></li>
						<li><a pageScroll href="#whatCanConnect">{{ 'IM_TXT6' | translate }}</a></li>
					</ul>
					<p>{{ 'IM_TXT7' | translate }}</p>
					<ul>
						<li><a pageScroll href="#config1">{{ 'IM_TXT8' | translate }}</a></li>
						<li><a pageScroll href="#config2">{{ 'IM_TXT9' | translate }}</a></li>
					</ul>
					<p>{{ 'IM_TXT10' | translate }}</p>
					<ul>
						<li><a pageScroll href="#rainSensor">{{ 'IM_TXT11' | translate }}</a></li>
						<li><a pageScroll href="#floodSensor">{{ 'IM_TXT12' | translate }}</a></li>
						<li><a pageScroll href="#freezingSensor">{{ 'IM_TXT13' | translate }}</a></li>
					</ul>
					<p>{{ 'IM_TXT14' | translate }}</p>
					<ul>
						<li><a pageScroll href="#thermostat">{{ 'IM_TXT15' | translate }}</a></li>
						<li><a pageScroll href="#pressureSwitch">{{ 'IM_TXT16' | translate }}</a></li>
						<li><a pageScroll href="#turbidity">{{ 'IM_TXT17' | translate }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-sm-9">
				<h4 id="interlocking"><strong>{{ 'IM_TXT18' | translate }}</strong></h4>
				<div>{{ 'IM_TXT19' | translate }}</div>
				<h4 id="howConnect"><strong>{{ 'IM_TXT20' | translate }}</strong></h4>
				<p>{{ 'IM_TXT21' | translate }}</p>
				<p class="text-center">
					<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/paso1.png'">
				</p>
					<h4 id="whatCanConnect"><strong>{{ 'IM_TXT22' | translate }}</strong></h4>
					<p>{{ 'IM_TXT23' | translate }}</p>
					<p>{{ 'IM_TXT24' | translate }}</p>
					<p class="text-center">
						<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/paso2.png'">
				</p>
						<h4><strong>{{ 'IM_TXT25' | translate }}</strong></h4>
						<strong><p id="config1">{{ 'IM_TXT26' | translate }}</p></strong>
						<p>{{ 'IM_TXT27' | translate }} (<img class="img-responsive" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/icono_entrada.png'">).</p>
							<p class="text-center">
								<img class="img-responsive" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/listado_ajustes.png'">
				</p>
								<strong><p id="config2">{{ 'IM_TXT28' | translate }}</p></strong>
								<p>{{ 'IM_TXT29' | translate }}</p>
								<p class="text-center">
									<img class="img-responsive" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/ajustes_entradas.png'">
				</p>
									<p>{{ 'IM_TXT30' | translate }}</p>
									<p>{{ 'IM_TXT31' | translate }}</p>
									<p>{{ 'IM_TXT32' | translate }}</p>
									<p>{{ 'IM_TXT33' | translate }}</p>
									<p class="text-center">
										<img class="img-responsive" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/ajustes_entradas_parametros.png'">
				</p>
										<h4><strong>{{ 'IM_TXT34' | translate }}</strong></h4>
										<h4 id="rainSensor">{{ 'IM_TXT35' | translate }}</h4>
										<p>{{ 'IM_TXT36' | translate }}</p>
										<p class="text-center">
											<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/tira-rele.png'">
				</p>
											<p>{{ 'IM_TXT37' | translate }}</p>
											<p class="text-center">
												<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/rele_aspersion.png'">
				</p>
												<p>{{ 'IM_TXT38' | translate }}</p>
												<p class="text-center">
													<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/entrada_digital_1.png'">
				</p>
													<h4 id="floodSensor">{{ 'IM_TXT39' | translate }}</h4>
													<p>{{ 'IM_TXT40' | translate }}</p>
													<p class="text-center">
														<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/entrada_digital_2.png'">
				</p>
														<h4 id="freezingSensor">{{ 'IM_TXT41' | translate }}</h4>
														<p>{{ 'IM_TXT42' | translate }}</p>
														<p class="text-center">
															<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/entrada_digital_3.png'">
				</p>
															<h4><strong>{{ 'IM_TXT43' | translate }}</strong></h4>
															<h4 id="thermostat">{{ 'IM_TXT44' | translate }}</h4>
															<p>{{ 'IM_TXT45' | translate }}</p>
															<p class="text-center">
																<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/entrada_analogica_1.png'">
				</p>
																<h4 id="pressureSwitch">{{ 'IM_TXT46' | translate }}</h4>
																<p>{{ 'IM_TXT47' | translate }}</p>
																<p class="text-center">
																	<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/entrada_analogica_2.png'">
				</p>
																	<h4 id="turbidity">{{ 'IM_TXT48' | translate }}</h4>
																	<p>{{ 'IM_TXT49' | translate }}</p>
																	<p class="text-center">
																		<img class="img-responsive" title="{{ 'IM_TXT51' | translate }}" alt="{{ 'IM_TXT51' | translate }}" [src]="'../../assets/images/dibujos_enclavamiento/' + lang + '/entrada_analogica_3.png'">
				</p>
			</div>
		</div>

		<div *ngSwitchCase="'legal'" class="container">
			<h2>{{ 'AVISO_LEGAL' | translate }}</h2>
			<p [innerHTML]="'AVISO_TEXTO' | translate"></p>
			<h3>{{ 'ACCESO_TITLE' | translate }}</h3>
			<p [innerHTML]="'ACCESO_TEXTO' | translate"></p>
			<h3>{{ 'EXONERACION_TITLE' | translate }}</h3>
			<p [innerHTML]="'EXONERACION_TEXTO' | translate"></p>
			<h3>{{ 'NO_GARANTIA' | translate }}</h3>
			<p [innerHTML]="'GARANTIA_TEXTO' | translate"></p>
			<h3>{{ 'DATA_PROTECTION_HEAD' | translate }}</h3>
			<p [innerHTML]="'DATA_PROTECTION_LEGAL_ADVICE' | translate"></p>
			<h3>{{ 'HEADING_COOKIES_PROTECTION' | translate }}</h3>
			<p [innerHTML]="'DATA_PROTECTION_LEGAL_COOKIES_ADVICE' | translate"></p>
			<h3>{{ 'PROPIEDAD_TITLE' | translate }}</h3>
			<p [innerHTML]="'PROPIEDAD_TEXTO' | translate"></p>
			<h3>{{ 'GUIA_USO' | translate }}</h3>
			<p [innerHTML]="'GUIA_USO_TEXTO' | translate"></p>
			<h3>{{ 'LEGISLACION_TITLE' | translate }}</h3>
			<p [innerHTML]="'LEGISLACION_TEXTO' | translate"></p>
		</div>

		<div *ngSwitchCase="'domind'">
			<div class="col-sm-3 hidden-xs">
				<div position-fixed>
					<h4>{{ 'DDM_TXT2' | translate }}</h4>
					<ul>
						<li><a pageScroll href="#INDRPH-200">{{ 'DDM_TXT3' | translate }}</a></li>
						<li><a pageScroll href="#INDRPH-201">{{ 'DDM_TXT4' | translate }}</a></li>
						<li><a pageScroll href="#INDRMV-200">{{ 'DDM_TXT5' | translate }}</a></li>
						<li><a pageScroll href="#INDRMV-201">{{ 'DDM_TXT6' | translate }}</a></li>
						<li><a pageScroll href="#INDPR-204">{{ 'DDM_TXT7' | translate }}</a></li>
						<li><a pageScroll href="#INDPR-206">{{ 'DDM_TXT8' | translate }}</a></li>
						<li><a pageScroll href="#INDPR-207">{{ 'DDM_TXT9' | translate }}</a></li>
						<li><a pageScroll href="#INDPR-3xx">{{ 'DDM_TXT10' | translate }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-sm-9">
				<h2>{{ 'DDM_TXT11' | translate }}</h2>
				<p>{{ 'DDM_TXT12' | translate }}</p>

				<h3 id="INDRPH-200"><strong>{{ 'DDM_TXT13' | translate }}</strong></h3>
				<p class="text-center">
					<img class="img-responsive" title="{{ 'DDM_TXT3' | translate }}" alt="{{ 'DDM_TXT3' | translate }}" src="../../assets/images/dom-ind/RPH200-DOMIND.png"/>
        		</p>
					<h3 id="INDRPH-201"><strong>{{ 'DDM_TXT14' | translate }}</strong></h3>
					<p class="text-center">
						<img class="img-responsive" title="{{ 'DDM_TXT4' | translate }}" alt="{{ 'DDM_TXT4' | translate }}" src="../../assets/images/dom-ind/RPH201-DOMIND.png"/>
        		</p>
						<h3 id="INDRMV-200"><strong>{{ 'DDM_TXT15' | translate }}</strong></h3>
						<p class="text-center">
							<img class="img-responsive" title="{{ 'DDM_TXT5' | translate }}" alt="{{ 'DDM_TXT5' | translate }}" src="../../assets/images/dom-ind/RMV200-DOMIND.png"/>
        		</p>
							<h3 id="INDRMV-201"><strong>{{ 'DDM_TXT16' | translate }}</strong></h3>
							<p class="text-center">
								<img class="img-responsive" title="{{ 'DDM_TXT6' | translate }}" alt="{{ 'DDM_TXT6' | translate }}" src="../../assets/images/dom-ind/RMV201-DOMIND.png"/>
        		</p>
								<h3 id="INDPR-204"><strong>{{ 'DDM_TXT17' | translate }}</strong></h3>
								<p class="text-center">
									<img class="img-responsive" title="{{ 'DDM_TXT7' | translate }}" alt="{{ 'DDM_TXT7' | translate }}" src="../../assets/images/dom-ind/PR204-DOMIND.png"/>
        		</p>
									<h3 id="INDPR-206"><strong>{{ 'DDM_TXT18' | translate }}</strong></h3>
									<p class="text-center">
										<img class="img-responsive" title="{{ 'DDM_TXT8' | translate }}" alt="{{ 'DDM_TXT8' | translate }}" src="../../assets/images/dom-ind/PR206-DOMIND.png"/>
        		</p>
										<h3 id="INDPR-207"><strong>{{ 'DDM_TXT19' | translate }}</strong></h3>
										<p class="text-center">
											<img class="img-responsive" title="{{ 'DDM_TXT9' | translate }}" alt="{{ 'DDM_TXT9' | translate }}" src="../../assets/images/dom-ind/PR207-DOMIND.png"/>
        		</p>
											<h3 id="INDPR-3xx"><strong>{{ 'DDM_TXT20' | translate }}</strong></h3>
											<p class="text-center">
												<img class="img-responsive" title="IND + PR320" alt="IND + PR320" src="../../assets/images/dom-ind/PR310-DOMIND.png" />
        		</p>
			</div>
		</div>

		<div *ngSwitchCase="'re300'">
			<div class="col-sm-3 hidden-xs">
				<div position-fixed>
					<h4>{{ 'RM300_TXT3' | translate }}</h4>
					<ul>
						<li><a pageScroll href="#PR-300DOM">{{ 'RM300_TXT4' | translate }}</a></li>
						<li><a pageScroll href="#PR-300IND">{{ 'RM300_TXT5' | translate }}</a></li>
						<li><a pageScroll href="#PR-300EX">{{ 'RM300_TXT6' | translate }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-sm-9 col-sm-offset-3">
				<h4>{{ 'RM300_TXT7' | translate }}</h4>
				<p>{{ 'RM300_TXT8' | translate }}</p>

				<h4 id="PR-300DOM"><strong>{{ 'RM300_TXT9' | translate }}</strong></h4>
				<p><img class="img-responsive" title="{{ 'RM300_TXT13' | translate }}" alt="{{ 'RM300_TXT13' | translate }}" src="../../assets/images/pr300/PR300-DOM.png"/></p>

					<h4 id="PR-300IND"><strong>{{ 'RM300_TXT10' | translate }}</strong></h4>
					<p><img class="img-responsive" title="{{ 'RM300_TXT14' | translate }}" alt="{{ 'RM300_TXT14' | translate }}" src="../../assets/images/pr300/PR310-DOMIND.png"/></p>

						<h4 id="PR-300EX"><strong>{{ 'RM300_TXT11' | translate }}</strong></h4>
						<p><img class="img-responsive" title="{{ 'RM300_TXT15' | translate }}" alt="{{ 'RM300_TXT15' | translate }}" src="../../assets/images/pr300/PR320-EX.png"/></p>
			</div>
		</div>

		<div *ngSwitchCase="'re200'" class="row">
			<div class="col-sm-3 hidden-xs">
				<div position-fixed>
					<h4>{{ 'RM_TXT3' | translate }}</h4>
					<ul>
						<li><a pageScroll href="#PR-204">{{ 'RM_TXT4' | translate }}</a></li>
						<li><a pageScroll href="#PR-206">{{ 'RM_TXT5' | translate }}</a></li>
						<li><a pageScroll href="#PR-207">{{ 'RM_TXT6' | translate }}</a></li>
						<li><a pageScroll href="#RPH-200DOM">{{ 'RM_TXT7' | translate }}</a></li>
						<li><a pageScroll href="#RPH-201DOM">{{ 'RM_TXT8' | translate }}</a></li>
						<li><a pageScroll href="#RMV-200DOM">{{ 'RM_TXT9' | translate }}</a></li>
						<li><a pageScroll href="#RMV-201DOM">{{ 'RM_TXT10' | translate }}</a></li>
						<li><a pageScroll href="#PR-204DOM">{{ 'RM_TXT11' | translate }}</a></li>
						<li><a pageScroll href="#PR-206DOM">{{ 'RM_TXT12' | translate }}</a></li>
						<li><a pageScroll href="#PR-207DOM">{{ 'RM_TXT13' | translate }}</a></li>
						<li><a pageScroll href="#RPH-200EX">{{ 'RM_TXT14' | translate }}</a></li>
						<li><a pageScroll href="#RPH-201EX">{{ 'RM_TXT15' | translate }}</a></li>
						<li><a pageScroll href="#RMV-200EX">{{ 'RM_TXT16' | translate }}</a></li>
						<li><a pageScroll href="#RMV-201EX">{{ 'RM_TXT17' | translate }}</a></li>
						<li><a pageScroll href="#PR-204EX">{{ 'RM_TXT18' | translate }}</a></li>
						<li><a pageScroll href="#PR-206EX">{{ 'RM_TXT19' | translate }}</a></li>
						<li><a pageScroll href="#PR-207EX">{{ 'RM_TXT20' | translate }}</a></li>
						<li><a pageScroll href="#RPH-200IND">{{ 'RM_TXT21' | translate }}</a></li>
						<li><a pageScroll href="#RPH-201IND">{{ 'RM_TXT22' | translate }}</a></li>
						<li><a pageScroll href="#RMV-200IND">{{ 'RM_TXT23' | translate }}</a></li>
						<li><a pageScroll href="#RMV-201IND">{{ 'RM_TXT24' | translate }}</a></li>
						<li><a pageScroll href="#PR-204IND">{{ 'RM_TXT25' | translate }}</a></li>
						<li><a pageScroll href="#PR-206IND">{{ 'RM_TXT26' | translate }}</a></li>
						<li><a pageScroll href="#PR-207IND">{{ 'RM_TXT27' | translate }}</a></li>
						<li><a pageScroll href="#DOMPH-RMV">{{ 'RM_TXT28' | translate }}</a></li>
						<li><a pageScroll href="#DOM-RPH-RMV">{{ 'RM_TXT29' | translate }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-sm-9">
				<h4>{{ 'RM_TXT30' | translate }}</h4>
				<p>{{ 'RM_TXT31' | translate }}</p>

				<h4 id="PR-204"><strong>{{ 'RM_TXT32' | translate }}</strong></h4>
				<p class="text-center">
					<img class="img-responsive" title="{{ 'RM_TXT4' | translate }}" alt="{{ 'RM_TXT4' | translate }}" src="../../assets/images/pr200/PR204.png"/>
        		</p>
					<h4 id="PR-206"><strong>{{ 'RM_TXT33' | translate }}</strong></h4>
					<p class="text-center">
						<img class="img-responsive" title="{{ 'RM_TXT5' | translate }}" alt="{{ 'RM_TXT5' | translate }}" src="../../assets/images/pr200/PR206.png"/>
        		</p>
						<h4 id="PR-207"><strong>{{ 'RM_TXT34' | translate }}</strong></h4>
						<p class="text-center">
							<img class="img-responsive" title="{{ 'RM_TXT6' | translate }}" alt="{{ 'RM_TXT6' | translate }}" src="../../assets/images/pr200/PR207.png"/>
        		</p>
							<h4 id="RPH-200DOM"><strong>{{ 'RM_TXT35' | translate }}</strong></h4>
							<p class="text-center">
								<img class="img-responsive" title="{{ 'RM_TXT7' | translate }}" alt="{{ 'RM_TXT7' | translate }}" src="../../assets/images/pr200/RPH200-DOM.png"/>
        		</p>
								<h4 id="RPH-201DOM"><strong>{{ 'RM_TXT36' | translate }}</strong></h4>
								<p class="text-center">
									<img class="img-responsive" title="{{ 'RM_TXT8' | translate }}" alt="{{ 'RM_TXT8' | translate }}" src="../../assets/images/pr200/RPH201-DOM.png"/>
        		</p>
									<h4 id="RMV-200DOM"><strong>{{ 'RM_TXT37' | translate }}</strong></h4>
									<p class="text-center">
										<img class="img-responsive" title="{{ 'RM_TXT9' | translate }}" alt="{{ 'RM_TXT9' | translate }}" src="../../assets/images/pr200/RMV200-DOM.png"/>
        		</p>
										<h4 id="RMV-201DOM"><strong>{{ 'RM_TXT38' | translate }}</strong></h4>
										<p class="text-center">
											<img class="img-responsive" title="{{ 'RM_TXT10' | translate }}" alt="{{ 'RM_TXT10' | translate }}" src="../../assets/images/pr200/RMV201-DOM.png"/>
        		</p>
											<h4 id="PR-204DOM"><strong>{{ 'RM_TXT39' | translate }}</strong></h4>
											<p class="text-center">
												<img class="img-responsive" title="{{ 'RM_TXT11' | translate }}" alt="{{ 'RM_TXT11' | translate }}" src="../../assets/images/pr200/PR204-DOM.png"/>
        		</p>
												<h4 id="PR-206DOM"><strong>{{ 'RM_TXT40' | translate }}</strong></h4>
												<p class="text-center">
													<img class="img-responsive" title="{{ 'RM_TXT12' | translate }}" alt="{{ 'RM_TXT12' | translate }}" src="../../assets/images/pr200/PR206-DOM.png"/>
        		</p>
													<h4 id="PR-207DOM"><strong>{{ 'RM_TXT41' | translate }}</strong></h4>
													<p class="text-center">
														<img class="img-responsive" title="{{ 'RM_TXT13' | translate }}" alt="{{ 'RM_TXT13' | translate }}" src="../../assets/images/pr200/PR207-DOM.png"/>
        		</p>
														<h4 id="RPH-200EX"><strong>{{ 'RM_TXT42' | translate }}</strong></h4>
														<p class="text-center">
															<img class="img-responsive" title="{{ 'RM_TXT14' | translate }}" alt="{{ 'RM_TXT14' | translate }}" src="../../assets/images/pr200/RPH200-EX.png"/>
        		</p>
															<h4 id="RPH-201EX"><strong>{{ 'RM_TXT43' | translate }}</strong></h4>
															<p class="text-center">
																<img class="img-responsive" title="{{ 'RM_TXT15' | translate }}" alt="{{ 'RM_TXT15' | translate }}" src="../../assets/images/pr200/RPH201-EX.png"/>
        		</p>
																<h4 id="RMV-200EX"><strong>{{ 'RM_TXT44' | translate }}</strong></h4>
																<p class="text-center">
																	<img class="img-responsive" title="{{ 'RM_TXT16' | translate }}" alt="{{ 'RM_TXT16' | translate }}" src="../../assets/images/pr200/RMV200-EX.png"/>
        		</p>
																	<h4 id="RMV-201EX"><strong>{{ 'RM_TXT45' | translate }}</strong></h4>
																	<p class="text-center">
																		<img class="img-responsive" title="{{ 'RM_TXT17' | translate }}" alt="{{ 'ERM_TXT17' | translate }}" src="../../assets/images/pr200/RMV201-EX.png"/>
        		</p>
																		<h4 id="PR-204EX"><strong>{{ 'RM_TXT46' | translate }}</strong></h4>
																		<p class="text-center">
																			<img class="img-responsive" title="{{ 'RM_TXT18' | translate }}" alt="{{ 'RM_TXT18' | translate }}" src="../../assets/images/pr200/PR204-EX.png"/>
        		</p>
																			<h4 id="PR-206EX"><strong>{{ 'RM_TXT47' | translate }}</strong></h4>
																			<p class="text-center">
																				<img class="img-responsive" title="{{ 'RM_TXT19' | translate }}" alt="{{ 'RM_TXT19' | translate }}" src="../../assets/images/pr200/PR206-EX.png"/>
        		</p>
																				<h4 id="PR-207EX"><strong>{{ 'RM_TXT48' | translate }}</strong></h4>
																				<p class="text-center">
																					<img class="img-responsive" title="{{ 'RM_TXT20' | translate }}" alt="{{ 'RM_TXT20' | translate }}" src="../../assets/images/pr200/PR207-EX.png"/>
				</p>
																					<h4 id="RPH-200IND"><strong>{{ 'RM_TXT49' | translate }}</strong></h4>
																					<p class="text-center">
																						<img class="img-responsive" title="{{ 'RM_TXT21' | translate }}" alt="{{ 'RM_TXT21' | translate }}" src="../../assets/images/pr200/RPH200-DOMIND.png"/>
				</p>
																						<h4 id="RPH-201IND"><strong>{{ 'RM_TXT50' | translate }}</strong></h4>
																						<p class="text-center">
																							<img class="img-responsive" title="{{ 'RM_TXT22' | translate }}" alt="{{ 'RM_TXT22' | translate }}" src="../../assets/images/pr200/RPH201-DOMIND.png"/>
				</p>
																							<h3 id="RMV-200IND"><strong>{{ 'RM_TXT51' | translate }}</strong></h3>
																							<p class="text-center">
																								<img class="img-responsive" title="{{ 'RM_TXT23' | translate }}" alt="{{ 'RM_TXT23' | translate }}" src="../../assets/images/pr200/RMV200-DOMIND.png"/>
				</p>
																								<h4 id="RMV-201IND"><strong>{{ 'RM_TXT52' | translate }}</strong></h4>
																								<p class="text-center">
																									<img class="img-responsive" title="{{ 'RM_TXT24' | translate }}" alt="{{ 'RM_TXT24' | translate }}" src="../../assets/images/pr200/RMV201-DOMIND.png"/>
				</p>
																									<h4 id="PR-204IND"><strong>{{ 'RM_TXT53' | translate }}</strong></h4>
																									<p class="text-center">
																										<img class="img-responsive" title="{{ 'RM_TXT25' | translate }}" alt="{{ 'RM_TXT25' | translate }}" src="../../assets/images/pr200/PR204-DOMIND.png"/>
				</p>
																										<h4 id="PR-206IND"><strong>{{ 'RM_TXT54' | translate }}</strong></h4>
																										<p class="text-center">
																											<img class="img-responsive" title="{{ 'RM_TXT26' | translate }}" alt="{{ 'RM_TXT26' | translate }}" src="../../assets/images/pr200/PR206-DOMIND.png"/>
				</p>
																											<h4 id="PR-207IND"><strong>{{ 'RM_TXT55' | translate }}</strong></h4>
																											<p class="text-center">
																												<img class="img-responsive" title="{{ 'RM_TXT27' | translate }}" alt="{{ 'RM_TXT27' | translate }}" src="../../assets/images/pr200/PR207-DOMIND.png"/>
				</p>
																												<h4 id="DOMPH-RMV"><strong>{{ 'RM_TXT56' | translate }}</strong></h4>
																												<p class="text-center">
																													<img class="img-responsive" title="DomoticPH + RMV" alt="DomoticPH + RMV" src="../../assets/images/domotic/RMV-DOMOTICPH.png"/>
				</p>
																													<h4 id="DOM-RPH-RMV"><strong>{{ 'RM_TXT57' | translate }}</strong></h4>
																													<p class="text-center">
																														<img class="img-responsive" title="Domotic + RPH + RMV" alt="Domotic + RPH + RMV" src="../../assets/images/domotic/RMV-RPH-DOMOTIC.png"/>
				</p>
			</div>
		</div>

		<div *ngSwitchCase="'ex'">
			<div class="col-sm-3 hidden-xs">
				<div position-fixed>
					<h4>{{ 'EM_TXT3' | translate }}</h4>
					<ul>
						<li><a pageScroll href="#EXRPH-200">{{ 'EM_TXT4' | translate }}</a></li>
						<li><a pageScroll href="#EXRPH-201">{{ 'EM_TXT5' | translate }}</a></li>
						<li><a pageScroll href="#EXRMV-200">{{ 'EM_TXT6' | translate }}</a></li>
						<li><a pageScroll href="#EXRMV-201">{{ 'EM_TXT7' | translate }}</a></li>
						<li><a pageScroll href="#EXPR-204">{{ 'EM_TXT8' | translate }}</a></li>
						<li><a pageScroll href="#EXPR-206">{{ 'EM_TXT9' | translate }}</a></li>
						<li><a pageScroll href="#EXPR-207">{{ 'EM_TXT10' | translate }}</a></li>
						<li><a pageScroll href="#EXPR-3xx">{{ 'EM_TXT11' | translate }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-sm-9">
				<h4>{{ 'EM_TXT12' | translate }}</h4>
				<p>{{ 'EM_TXT13' | translate }}</p>

				<h4 id="EXRPH-200"><strong>{{ 'EM_TXT14' | translate }}</strong></h4>
				<p class="text-center">
					<img class="img-responsive" title="{{ 'EM_TXT4' | translate }}" alt="{{ 'EM_TXT4' | translate }}" src="../../assets/images/ex/RPH200-EX.png"/>
				</p>
					<h4 id="EXRPH-201"><strong>{{ 'EM_TXT15' | translate }}</strong></h4>
					<p class="text-center">
						<img class="img-responsive" title="{{ 'EM_TXT5' | translate }}" alt="{{ 'EM_TXT5' | translate }}" src="../../assets/images/ex/RPH201-EX.png"/>
				</p>
						<h4 id="EXRMV-200"><strong>{{ 'EM_TXT16' | translate }}</strong></h4>
						<p class="text-center">
							<img class="img-responsive" title="{{ 'EM_TXT6' | translate }}" alt="{{ 'EM_TXT6' | translate }}" src="../../assets/images/ex/RMV200-EX.png"/>
				</p>
							<h4 id="EXRMV-201"><strong>{{ 'EM_TXT17' | translate }}</strong></h4>
							<p class="text-center">
								<img class="img-responsive" title="{{ 'EM_TXT7' | translate }}" alt="{{ 'EM_TXT7' | translate }}" src="../../assets/images/ex/RMV201-EX.png"/>
				</p>
								<h4 id="EXPR-204"><strong>{{ 'EM_TXT18' | translate }}</strong></h4>
								<p class="text-center">
									<img class="img-responsive" title="{{ 'EM_TXT8' | translate }}" alt="{{ 'EM_TXT8' | translate }}" src="../../assets/images/ex/PR204-EX.png"/>
				</p>
									<h4 id="EXPR-206"><strong>{{ 'EM_TXT19' | translate }}</strong></h4>
									<p class="text-center">
										<img class="img-responsive" title="{{ 'EM_TXT9' | translate }}" alt="{{ 'EM_TXT9' | translate }}" src="../../assets/images/ex/PR206-EX.png"/>
				</p>
										<h4 id="EXPR-207"><strong>{{ 'EM_TXT20' | translate }}</strong></h4>
										<p class="text-center">
											<img class="img-responsive" title="{{ 'EM_TXT10' | translate }}" alt="{{ 'EM_TXT10' | translate }}" src="../../assets/images/ex/PR207-EX.png"/>
				</p>
											<h4 id="EXPR-3xx"><strong>{{ 'EM_TXT21' | translate }}</strong></h4>
											<p class="text-center">
												<img class="img-responsive" title="{{ 'EM_TXT11' | translate }}" alt="{{ 'EM_TXT11' | translate }}" src="../../assets/images/ex/PR320-EX.png"/>
				</p>
			</div>
		</div>

		<div *ngSwitchCase="'domotic'">
			<div class="col-sm-3 hidden-xs">
				<div position-fixed>
					<h4>{{ 'DM_TXT3' | translate }}</h4>
					<ul>
						<li><a pageScroll href="#DOMRPH-200">{{ 'DM_TXT4' | translate }}</a></li>
						<li><a pageScroll href="#DOMRPH-201">{{ 'DM_TXT5' | translate }}</a></li>
						<li><a pageScroll href="#DOMRMV-200">{{ 'DM_TXT6' | translate }}</a></li>
						<li><a pageScroll href="#DOMRMV-201">{{ 'DM_TXT7' | translate }}</a></li>
						<li><a pageScroll href="#DOMPR-204">{{ 'DM_TXT8' | translate }}</a></li>
						<li><a pageScroll href="#DOMPR-206">{{ 'DM_TXT9' | translate }}</a></li>
						<li><a pageScroll href="#DOMPR-207">{{ 'DM_TXT10' | translate }}</a></li>
						<li><a pageScroll href="#DOMPR-3xx">{{ 'DM_TXT11' | translate }}</a></li>
						<li><a pageScroll href="#DOMPHRMV-200">{{ 'DM_TXT12' | translate }}</a></li>
						<li><a pageScroll href="#DOMRPH-200RMV-200">{{ 'DM_TXT13' | translate }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-sm-9">
				<h4>{{ 'DM_TXT14' | translate }}</h4>
				<p>{{ 'DM_TXT15' | translate }}</p>

				<h4 id="DOMRPH-200"><strong>{{ 'DM_TXT16' | translate }}</strong></h4>
				<p class="text-center">
					<img class="img-responsive" title="{{ 'DM_TXT4' | translate }}" alt="{{ 'DM_TXT4' | translate }}" src="../../assets/images/domotic/RPH200-DOM.png"/>
				</p>
					<h4 id="DOMRPH-201"><strong>{{ 'DM_TXT17' | translate }}</strong></h4>
					<p class="text-center">
						<img class="img-responsive" title="{{ 'DM_TXT5' | translate }}" alt="{{ 'DM_TXT5' | translate }}" src="../../assets/images/domotic/RPH201-DOM.png"/>
				</p>
						<h4 id="DOMRMV-200"><strong>{{ 'DM_TXT18' | translate }}</strong></h4>
						<p class="text-center">
							<img class="img-responsive" title="{{ 'DM_TXT6' | translate }}" alt="{{ 'DM_TXT6' | translate }}" src="../../assets/images/domotic/RMV200-DOM.png"/>
				</p>
							<h4 id="DOMRMV-201"><strong>{{ 'DM_TXT19' | translate }}</strong></h4>
							<p class="text-center">
								<img class="img-responsive" title="{{ 'DM_TXT7' | translate }}" alt="{{ 'DM_TXT7' | translate }}" src="../../assets/images/domotic/RMV201-DOM.png"/>
				</p>
								<h4 id="DOMPR-204"><strong>{{ 'DM_TXT20' | translate }}</strong></h4>
								<p class="text-center">
									<img class="img-responsive" title="{{ 'DM_TXT8' | translate }}" alt="{{ 'DM_TXT8' | translate }}" src="../../assets/images/domotic/PR204-DOM.png"/>
				</p>
									<h4 id="DOMPR-206"><strong>{{ 'DM_TXT21' | translate }}</strong></h4>
									<p class="text-center">
										<img class="img-responsive" title="{{ 'DM_TXT9' | translate }}" alt="{{ 'DM_TXT9' | translate }}" src="../../assets/images/domotic/PR206-DOM.png"/>
				</p>
										<h4 id="DOMPR-207"><strong>{{ 'DM_TXT22' | translate }}</strong></h4>
										<p class="text-center">
											<img class="img-responsive" title="{{ 'DM_TXT10' | translate }}" alt="{{ 'DM_TXT10' | translate }}" src="../../assets/images/domotic/PR207-DOM.png"/>
				</p>
											<h3 id="DOMPR-3xx"><strong>{{ 'DM_TXT23' | translate }}</strong></h3>
											<p class="text-center">
												<img class="img-responsive" title="{{ 'DM_TXT27' | translate }}" alt="{{ 'DM_TXT27' | translate }}" src="../../assets/images/domotic/PR300-DOM.png"/>
				</p>
												<h4 id="DOMPHRMV-200"><strong>{{ 'DM_TXT24' | translate }}</strong></h4>
												<p class="text-center">
													<img class="img-responsive" title="{{ 'DM_TXT28' | translate }}" alt="{{ 'DM_TXT28' | translate }}" src="../../assets/images/domotic/RMV-DOMOTICPH.png"/>
				</p>
													<h4 id="DOMRPH-200RMV-200"><strong>{{ 'DM_TXT25' | translate }}</strong></h4>
													<p class="text-center">
														<img class="img-responsive" title="{{ 'DM_TXT29' | translate }}" alt="{{ 'DM_TXT29' | translate }}" src="../../assets/images/domotic/RMV-RPH-DOMOTIC.png"/>
				</p>
			</div>
		</div>

		<!-- <div *ngSwitchCase="'walkthrough'" class="video">
			<div class="container col-sm-6 col-sm-offset-3">
				<iframe frameborder="0" width="100%" height="400" src="https://www.youtube.com/embed/crFdx2fAH1w?autoplay=1"></iframe>
			</div>
		</div> -->

		<div *ngSwitchCase="'thanks'">
			<div class="wrapper container">
				<div class="titleContainer">
					<h3 class="title">{{ "AGRADECIMIENTOS" | translate }}</h3>
				</div>
				<div>
					<div title="{{ 'AGRADECIMIENTOS' | translate }}">
						<p>{{ "IDEGIS_AGRADECE" | translate }}</p>
						<ul>
							<li>&bull; <span [innerHtml]="'ATTRIBUTION_WEATHER' | translate"></span></li>
							<li>&bull; <span [innerHtml]="'ATTRIBUTION_GEOLOCATION' | translate"></span></li>
						</ul>
					</div>
				</div>
			</div>
		</div>

	</div>
</main>
